import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DividerModule } from 'primeng/divider';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { AmmDataLabelValue } from '../../../components.global';
import { ProductService } from '../../services';
@Component({
  selector: 'amm-search-filter',
  standalone: true,
  imports: [
    ButtonModule,
    DividerModule,
    CheckboxModule,
    InputTextModule,
    SliderModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputNumberModule,
    ChipModule,
  ],
  templateUrl: './amm-search-filter.component.html',
  styleUrl: './amm-search-filter.component.scss',
})
export class AmmSearchFilterComponent implements OnInit {
  @Input()
  searchKey?: string;

  @Output()
  filterConditon = new EventEmitter<string>();
  @Output()
  showFilter = new EventEmitter<boolean>();

  public productService: ProductService = inject(ProductService);
  ngOnInit(): void {
    this.selectedFilter=this.filters[0];
    this.productService.getFilterCondition('com').subscribe(item=>{
      console.log(item.groupCountsMap);
      if(item.groupCountsMap){
        const info=item.groupCountsMap;
        if (info.categgory.id){
          
        }
        if (info.curPrice.amount){

        }

      }
    }

    )
    this.minSelected=this.minPrice+(this.maxPrice-this.minPrice)*this.priceRange[0]*0.01;
    this.maxSelected=this.minPrice+(this.maxPrice-this.minPrice)*this.priceRange[1]*0.01;
  }

  minPrice: number = 0;
  maxPrice: number = 100;
  minSelected: number = 0;
  maxSelected: number = 100;
  filterCount: number = 0;
  priceRange: number[] = [20, 80];
  getFilterButtonTitle(): string {
    return 'Clear';
    // return 'Clear ('+this.filterCount+')';      //等后端做好改为这个
  }
  selectedFilter?: AmmDataLabelValue;
  filters: Array<AmmDataLabelValue> = [
    { label: 'Most Relevant', value: 'RELEVANT' },
    { label: 'Price Low to High', value: 'PRICE' },
    { label: 'Price High to Low', value: 'RELEVANT' },
  ];
  selectPrice(): void {
    this.minSelected =
      this.minPrice +
      (this.maxPrice - this.minPrice) * this.priceRange[0] * 0.01;
    this.maxSelected =
      this.minPrice +
      (this.maxPrice - this.minPrice) * this.priceRange[1] * 0.01;
  }
  closeFilterPanel():void{
    this.showFilter.emit(false);
  }
}
