<div class="flex flex-row items-center">
<div class="filterPanel flex flex-col pap-4">
    <div class="top-box flex flex-row justify-between">
        <div class="title">Filter & Sort</div>
        <p-button [label]="getFilterButtonTitle()" [rounded]="true" severity="secondary" size="small"></p-button>
    </div>
    <div class="main-part flex flex-col gap-6">
        <div>
            <div class="title">Sort</div>
            <div class="sort-radios flex flex-col gap-4">
                <div *ngFor="let filter of filters" class="field-checkbox">
                    <p-radioButton class="radioButton" [inputId]="filter.value" name="category" [value]="filter"
                        [(ngModel)]="selectedFilter" />
                    <label [for]="filter.value" class="ml-2 text-base font-medium lineheight-150">
                        {{ filter.label }}
                    </label>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-4">
            <div class="title">Price range</div>
            <div class="price-description">Product prices before taxes and fees.</div>
            <p-slider class="slider" [style]="{width:'280px'}" [(ngModel)]="priceRange" [range]="true"
                (ngModelChange)="selectPrice()"></p-slider>
            <div class="price-part w-full flex flex-row items-center justify-between gap-2">
                <div>
                    <div class="value-label">Min</div>
                    <p-inputGroup>

                        <p-inputGroupAddon>$</p-inputGroupAddon>
                        <!-- <input type="text" placeholder="0.00" /> -->
                        <p-inputNumber [style]="{width:'90px'}" [(ngModel)]="minSelected" inputId="minmaxfraction"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" />
                    </p-inputGroup>
                </div>
                <span class="mt-4"> - </span>
                <div>
                    <div class="value-label">Max</div>
                    <p-inputGroup>
                        <p-inputGroupAddon>$</p-inputGroupAddon>
                        <!-- <input type="text" placeholder="10.00" /> -->
                        <p-inputNumber [style]="{width:'90px'}" [(ngModel)]="maxSelected" inputId="minmaxfraction"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" />
                    </p-inputGroup>
                </div>
            </div>
        </div>
        <div>
            <div class="title mb-6">Category</div>
            <div class="flex flex-col gap-2">
                <p-chip label="Books" />
                <p-chip label="Tools" />
                <p-chip label="Pets" />
            </div>
        </div>
    </div>
    <div class="button-contant">
        <p-button [style]="{width:'280px'}" [label]="'Show results'" severity="contrast" [rounded]="true"></p-button>
    </div>
</div>
<i class="close-button pi pi-chevron-left" (click)="closeFilterPanel()"></i>
</div>
